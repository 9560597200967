import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '15px 0',
  },
}));

export default function ClientForm({ onCancel, client, onSubmit, serverError }) {
  const classes = useStyles();

  const [currentClient, setCurrentClient] = useState({
    firstName: client?.firstName ? client.firstName : '',
    lastName: client?.lastName ? client.lastName : '',
    username: client?.username ? client.username : '',
    occupation: client?.occupation ? client.occupation : '',
    token: client?.token ? client.token : '',
  });
  const [error, setError] = useState(serverError);

  const handleChange = (e) => {
    error && setError('');
    setCurrentClient({
      ...currentClient,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setError(serverError);
  }, [serverError]);

  return (
    <form>
      <TextField
        margin="normal"
        fullWidth
        id="firstName"
        label="First Name"
        name="firstName"
        autoComplete="name"
        value={currentClient.firstName}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="lastName"
        label="Last Name"
        name="lastName"
        autoComplete="lastName"
        value={currentClient.lastName}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="username"
        label="Username"
        name="username"
        autoComplete="username"
        value={currentClient.username}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="occupation"
        label="Occupation"
        name="occupation"
        autoComplete="occupation"
        value={currentClient.occupation}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="token"
        label="Token"
        name="token"
        autoComplete="token"
        value={currentClient.token}
        onChange={handleChange}
      />
      <Typography color="secondary">{error}</Typography>
      <div className={classes.btns}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: '10px' }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            !currentClient.token ? setError('Token is required!') : onSubmit(currentClient);
          }}
        >
          Save
        </Button>
      </div>
    </form>
  );
}
