export default function columns() {
  return [
    {
      column_name: 'Image',
    },
    {
      column_name: 'Name',
    },
    {
      column_name: 'Username',
    },
    {
      column_name: 'Occupation',
    },
    {
      column_name: 'Token status',
    },
    {
      column_name: 'Actions',
    },
  ];
}
