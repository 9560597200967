import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import API from '../API';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import tableColumns from './../utils/companiesTable';
import Title from './../components/Title';
import Avatar from '@material-ui/core/Avatar';
import { Button } from '@material-ui/core';
import { formatDate, formatTime } from './../helpers/formatDate';
import { useClientStore, useClientDispatch } from '../contexts/ClientContext';
import { FaToggleOn } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  formControl: {
    minWidth: 120,
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  switch: {
    fontSize: '30px',
    cursor: 'pointer',
  },
}));

export default function Companies() {
  const classes = useStyles();
  const { selectedClient, selectedClientCompanies } = useClientStore();
  const dispatch = useClientDispatch();
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const getClients = async () => {
      try {
        const res = await API.getClientsCompanies();
        setClients(res.data);
      } catch (error) {
        console.log('error -> ', error);
      }
    };
    getClients();
  }, []);

  useEffect(() => {
    if (!selectedClient.id && clients.length !== 0) {
      dispatch({ type: 'SET_CLIENT', value: clients[0] });
    }
  }, [clients, dispatch, selectedClient]);

  const patchSetRead = async (companyId, read) => {
    try {
      let company = await API.patchSetRead({ companyId, read });
      let index = selectedClientCompanies.findIndex((obj) => obj.id === company.data.id);
      const copyCompanies = [...selectedClientCompanies];
      copyCompanies[index].read = company.data.read;
      dispatch({ type: 'SET_COMPANIES', value: [...copyCompanies] });
    } catch (error) {
      console.log('error -> ', error);
    }
  };

  const fetchNow = async (e, companyId) => {
    const token = clients.find((c) => c.id === selectedClient.id).token;
    if (token !== null) {
      try {
        let company = await API.postFetchNow({ companyId });
        let index = selectedClientCompanies.findIndex((obj) => obj.id === company.data.id);
        const copyCompanies = [...selectedClientCompanies];
        copyCompanies[index].read = company.data.read;
        dispatch({ type: 'SET_COMPANIES', value: [...copyCompanies] });
      } catch (error) {
        console.log('error -> ', error);
      }
    }
  };

  const handleClientChange = (e) => {
    const client = clients.find((c) => c.id === e.target.value);
    dispatch({ type: 'SET_CLIENT', value: { ...client } });
  };

  const getText = () => {
    if (clients.length > 0) {
      const token = clients.find((c) => c.id === selectedClient.id).token;
      if (token === null) {
        return 'MISSING TOKEN';
      } else {
        return 'FETCH NOW';
      }
    }
  };

  return (
    <Grid item md={12}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          marginBottom: '20px',
        }}
      >
        <FormControl className={classes.formControl}>
          <InputLabel id="client-label">Client</InputLabel>
          <Select
            labelId="client-label"
            id="client"
            value={selectedClient.id && clients.length > 0 ? selectedClient.id : ''}
            onChange={handleClientChange}
            disabled={clients.length === 1}
          >
            {clients.length !== 0 &&
              clients.map((client, i) => {
                return (
                  <MenuItem key={i} value={client.id}>
                    {client.name || client.lastName
                      ? `${client.name ? client.name : ''} ${
                          client.lastName ? client.lastName : ''
                        }`
                      : `client_id: ${client.id}`}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>
      <Paper className={classes.paper}>
        <Title>Companies</Title>
        <Table size="medium">
          <TableHead>
            <TableRow>
              {tableColumns &&
                tableColumns().map((cell, i) => {
                  return <TableCell key={i}>{cell.column_name}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedClientCompanies &&
              selectedClientCompanies.length !== 0 &&
              selectedClientCompanies.map((company, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {company.imageURL ? (
                        <img alt="Company" src={company.imageURL} className={classes.largeAvatar} />
                      ) : (
                        <Avatar className={classes.largeAvatar} />
                      )}
                    </TableCell>
                    <TableCell>{company.name}</TableCell>
                    <TableCell>
                      {company.read ? (
                        <FaToggleOn
                          title="On"
                          className={classes.switch}
                          style={{ color: '#3f51b5' }}
                          onClick={() => patchSetRead(company.id, !company.read)}
                        />
                      ) : (
                        <FaToggleOn
                          title="Off"
                          className={classes.switch}
                          style={{ color: 'gray', transform: 'rotate(180deg)' }}
                          onClick={() => patchSetRead(company.id, !company.read)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {company.lastFetch
                        ? `${formatDate(company.lastFetch)} - ${formatTime(company.lastFetch)}`
                        : ''}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.Button}
                        onClick={(e) => fetchNow(e, company.id)}
                      >
                        {getText(company)}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
    </Grid>
  );
}
