import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import API from '../API';
import { Typography } from '@material-ui/core';
import TagControl from './TagControl';
import Link from '@material-ui/core/Link';
import { ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: '20px 0',
  },
  submitBtn: {
    margin: '15px 0 0 auto',
  },
  nextBtn: {
    alignSelf: 'flex-end',
  },
}));

export default function EditFollower({
  follower = {},
  companyId = -1,
  onEditedFollower = null,
  nameQuery = '',
  selectedTags = [],
  which = null,
  index = null,
  isHistory = false,
  date= null,
  changeFollower,
 
}) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [note, setNote] = useState('');
  const [phone, setPhone] = useState('');
  const [currentTags, setCurrentTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [timer, setTimer] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(index);
  const [disableNextBtn, setDisableNextBtn] = useState(follower.count > currentIndex ? false : true);

  let handleDelete = (index) => {
    currentTags.splice(index, 1);
    setCurrentTags([...currentTags]);
  };
  let handleAddition = (newTag) => {
    setCurrentTags([...currentTags, newTag]);
  };

  const handleInputChange = async (value) => {
    if (timer !== null) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        getTags(value);
      }, 200)
    );
  };
 
  const getTags = async (value) => {
    try {
      if (value.length >= 2) {
        let found = await API.getTags(value);
        setSuggestions(
          found.data.map((item) => {
            return item.name;
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const { email, note, phone, tags } = follower.follower_companies[0];
    setEmail(email ? email : '');
    setUsername(follower.username ? follower.username : '');
    setNote(note ? note : '');
    setPhone(phone ? phone : '');
    let unpackedTags = tags.map((tag) => tag.name);
    setCurrentTags([...unpackedTags]);
  }, [follower]);

  const getEditedFollower = () => {
    return {
      ...follower,
      follower_companies: [
        {
          ...follower.follower_companies[0],
          note,
          phone,
          email,
          tags: currentTags.map((item) => {
            return {
              name: item,
            };
          }),
        },
      ],
    };
  };

  const handleAddData = async () => {
    try {
      if (companyId === -1) {
        console.log('companyId not set');
        return;
      }

      await API.follower_companyPatch({
        companyId,
        followerId: follower.id,
        note,
        phone,
        email,
        tags: currentTags,
      });

      onEditedFollower(getEditedFollower());
    } catch (err) {
      console.log(err);
    }
  };
  const [changedFollowers, setChangedFollowers] = useState([]);
  const addFollower = (follower) => {
    setChangedFollowers([...changedFollowers, getEditedFollower(follower)]);
  };

  const next = async () => {
    setDisableNextBtn(true);
    try {
      if (companyId === -1) {
        console.log('companyId not set');
        return;
      }
      addFollower(follower);
      let queryObject = {
        params: {
          which,
          tagName: selectedTags.length > 0 ? selectedTags.map((e) => e.label).join(' ') : undefined,
          name: nameQuery ? nameQuery : undefined,
          pageNumber: currentIndex + 1,
          pageSize: 1,
          searchDate: date
        },
      };

      if (isHistory){

        const result = await API.getHistoryFollowers(companyId, queryObject)
        changeFollower(result.data.history[0]);
        setDisableNextBtn(follower.count > (currentIndex+1) ? false : true)
      }
      else{
        const result = await API.getFollowers(companyId, queryObject)
        changeFollower(result.data.followers[0]);
        setDisableNextBtn(follower.count > (currentIndex+1) ? false : true)
      
      }
      setCurrentIndex(currentIndex + 1);
      setDisableNextBtn(false)

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '800px',
        margin: 'auto',
        padding: '0 0 20px 0',
      }}
    >
      {' '}
      <IconButton className={classes.nextBtn} onClick={next} title="Next" disabled={disableNextBtn}>
        <ArrowForward />
      </IconButton>
      <Avatar alt="Follower" src={follower.imageURL} className={classes.largeAvatar} />
      <Typography>{follower.name}</Typography>
      <Typography>{follower.lastName}</Typography>
      <Typography>{follower.position}</Typography>
      <Typography style={{ minHeight: '24px' }}>
        {username && (
          <Link href={`https://www.linkedin.com/in/${username}/`} target="_blank">
            View LinkedIn Profile
          </Link>
        )}
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="name"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="note"
        label="Note"
        name="note"
        autoComplete="note"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="phone"
        label="Phone Number"
        name="phone"
        autoComplete="phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <TagControl
        tags={currentTags}
        suggestionsParam={suggestions}
        handleDeleteFunc={handleDelete}
        handleAdditionFunc={handleAddition}
        handleInputChangeFunc={handleInputChange}
      />
      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        onClick={() => handleAddData()}
      >
        Save Changes
      </Button>
    </form>
  );
}
