export default function columns() {
  return [
    {
      column_name: '',
    },
    {
      column_name: 'Name',
    },
    {
      column_name: 'Work position',
    },
    {
      column_name: 'Started Following',
    },
    {
      column_name: 'Email',
    },
    {
      column_name: 'Tags',
    },
  ];
}
