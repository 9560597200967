export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CLIENT':
      return {
        ...state,
        selectedClient: action.value,
        selectedClientCompanies: action.value.companies,
        selectedCompany: action.value.companies[0]
          ? action.value.companies[0]
          : { id: '', name: '' },
      };
    case 'SET_COMPANY':
      return {
        ...state,
        selectedCompany: action.value,
      };
    case 'SET_COMPANIES':
      return {
        ...state,
        selectedClientCompanies: action.value,
      };
    default:
      return state;
  }
};
