export default function columns() {
  return [
    {
      column_name: 'Company Logo',
    },
    {
      column_name: 'Company Name',
    },
    {
      column_name: 'Fetching Data',
    },
    {
      column_name: 'Last Fetched',
    },
    {
      column_name: 'Fetch Now',
    },
  ];
}
