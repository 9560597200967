import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles(() => ({
  modalBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
  modalPaper: {
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    overflow: 'hidden',
  },
  modalHeader: {
    backgroundColor: '#3f51b5',
    minHeight: '60px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
  },
  modalHeaderTitle: {
    flex: '1',
    fontSize: '18px',
  },
  modalContent: {
    padding: '20px',
  },
  closeBtn: {
    color: 'white',
  },
}));

export default function Clients({ isOpen, onClose, title, children }) {
  const classes = useStyles();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modalBox}>
        <Paper className={classes.modalPaper}>
          <div className={classes.modalHeader}>
            <span className={classes.modalHeaderTitle}>{title}</span>
            <IconButton className={classes.closeBtn} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.modalContent}>{children}</div>
        </Paper>
      </Box>
    </Modal>
  );
}
