import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  sugestionsWrapper: {
    position: 'relative',
  },
  sugestions: {
    position: 'absolute',
    width: '100%',
    zIndex: '10',
    boxShadow: '1px 1px 10px #9ba0a4',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
  },
  sugestionTitle: {
    padding: '0 10px',
    fontWeight: '600',
  },
  sugestion: {
    padding: '3px 10px',
    '&:nth-child(odd)': {
      backgroundColor: '#d8d8d8',
    },
  },
  withLastNewTag: {
    '&:last-child': {
      borderTop: '2px solid #9ba0a4',
      '&::after': {
        content: '"(New Tag)"',
        display: 'inline-block',
        marginLeft: '10px',
      },
    },
  },
  sugestionHighligth: {
    backgroundColor: '#3f51b5 !important',
    color: '#ffffff',
  },
  tagContainer: {
    width: '100%',
  },
  btnTag: {
    backgroundColor: 'lightgray',
    margin: '10px 5px 10px 0',
    '&:hover': {
      backgroundColor: '#dc3545',
      color: 'white',
    },
  },
}));

export default function TagControl({
  tags,
  suggestionsParam,
  handleDeleteFunc = null,
  handleAdditionFunc = null,
  handleInputChangeFunc = null,
}) {
  const classes = useStyles();
  const [sugestions, setSugestions] = useState([]);
  const [sugestionIndex, setSugestionIndex] = useState(-1);
  const [tagValue, setTagValue] = useState('');
  const [feedbackMsg, setFeedbackMsg] = useState('');
  const [lastIsNewTag, setLastIsNewTag] = useState(false);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    const isInList = (value) => {
      const inList = suggestionsParam.find((tag) => {
        if (tag === value) {
          return true;
        } else {
          return false;
        }
      });
      return inList;
    };
    if (!edited) return;
    if (tagValue.length > 1) {
      if (isInList(tagValue)) {
        setSugestions([...suggestionsParam]);
        setLastIsNewTag(false);
      } else {
        setSugestions([...suggestionsParam, tagValue]);
        setLastIsNewTag(true);
      }
    }
  }, [suggestionsParam, tagValue, edited]);

  useEffect(() => {
    if (sugestionIndex !== -1) {
      setTagValue(sugestions[sugestionIndex]);
      setEdited(false);
    }
  }, [sugestionIndex, sugestions]);

  useEffect(() => {
    // arrows keys not affecting input filed
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  });

  const checkIfDuplicated = (value) => {
    const isDuplicated = tags.find((tag) => {
      if (tag === value) {
        setFeedbackMsg('This tag is already added');
        return true;
      } else {
        return false;
      }
    });
    return isDuplicated;
  };

  const onKeyUp = (event) => {
    setTagValue(event.target.value.trim());
    event.target.value = event.target.value.replaceAll(',', '');
    if (event.key === 'Enter' || event.key === ',') {
      if (checkIfDuplicated(event.target.value)) {
        return;
      }
      if (sugestionIndex === -1 && !event.target.value) {
        return;
      } else if (sugestionIndex === -1) {
        handleAdditionFunc(event.target.value);
        setSugestions([]);
      } else {
        handleAdditionFunc(sugestions[sugestionIndex]);
        setSugestions([]);
      }
      setEdited(false);
      setSugestionIndex(-1);
      setTagValue('');
    } else if (event.key === 'ArrowDown') {
      setFeedbackMsg('');
      let next = sugestionIndex + 1;
      if (next < sugestions.length) {
        setTagValue(event.target.value);
        setEdited(false);
        setSugestionIndex(next);
      }
    } else if (event.key === 'ArrowUp') {
      setFeedbackMsg('');
      event.preventDefault();
      let next = sugestionIndex - 1;
      if (next >= 0) {
        setTagValue(event.target.value);
        setEdited(false);
        setSugestionIndex(next);
      }
    } else if (event.key === 'Escape') {
      setEdited(false);
      setSugestions([]);
      setSugestionIndex(-1);
    } else {
      handleInputChangeFunc(event.target.value);
      setEdited(true);
      setSugestionIndex(-1);
      setSugestions([]);
    }
  };

  const onMouseEnter = (index) => {
    setSugestionIndex(index);
  };

  const onClickSugestion = (tag) => {
    if (checkIfDuplicated(tag)) {
      return;
    }
    handleAdditionFunc(sugestions[sugestionIndex]);
    setSugestionIndex(-1);
    setTagValue('');
    setSugestions([]);
  };

  return (
    <div className={classes.tagContainer}>
      <div>
        <TextField
          fullWidth
          label="Add Tag"
          name="Add Tag"
          margin="normal"
          autoComplete="off"
          onKeyUp={onKeyUp}
          value={tagValue}
          onChange={(e) => {
            setFeedbackMsg('');
            setTagValue(e.target.value);
          }}
          helperText={feedbackMsg}
        />
        <div className={classes.sugestionsWrapper}>
          {sugestions.length >= 1 && (
            <div className={classes.sugestions}>
              <div className={classes.sugestionTitle}>Suggestions</div>
              {sugestions.map((item, index) => {
                return (
                  <div
                    key={index}
                    onMouseEnter={() => onMouseEnter(index)}
                    onClick={() => onClickSugestion(item)}
                    className={`${classes.sugestion} ${
                      index === sugestionIndex && classes.sugestionHighligth
                    }
                    ${lastIsNewTag && classes.withLastNewTag}
                    `}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {tags &&
        tags.length !== 0 &&
        tags.map((item, index) => {
          return (
            <div key={index} style={{ display: 'inline-block' }}>
              <Button
                className={classes.btnTag}
                variant="contained"
                onClick={() => handleDeleteFunc(index)}
                title="Delete Tag"
                endIcon={<DeleteIcon />}
              >
                {item}
              </Button>
            </div>
          );
        })}
    </div>
  );
}
