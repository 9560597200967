import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import API from '../API';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import tableColumns from './../utils/followersTable';
import Avatar from '@material-ui/core/Avatar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import EditFollower from './../components/EditFollower';
import Pagination from './../components/Pagination';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { formatDate } from './../helpers/formatDate';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useClientStore, useClientDispatch } from '../contexts/ClientContext';
import ReactSelect from 'react-select';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'auto',
  },
  menu: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  hideMenu: {
    visibility: 'hidden',
  },
  icon: {
    color: '#3f51b5',
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cellStyle: {
    maxWidth: '250px',
  },
  smallCell: {
    width: '80px',
  },
  nameCell: {
    width: '180px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  hide: {
    opacity: 0,
    position: 'absolute',
    height: 0,
    overflow: 'hidden',
    zIndex: -1,
  },
  formControl: {
    marginRight: theme.spacing(3),
    minWidth: 120,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  formControlSearch: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  searchInput: {
    paddingRight: '10px',
  },
  downloadBtn: {
    marginLeft: 'auto',
  },
  expandCell: {
    width: '100%',
    textAlign: 'center',
  },
  tag: {
    display: 'block',
  },
  records: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '10px',
    '& span': {
      marginRight: '10px',
    },
  },
}));

export default function Followers() {
  const classes = useStyles();
  const [status, setStatus] = useState('current');
  const [followers, setFollowers] = useState([]);
  const [selectedFollower, setSelectedFollower] = useState(null);
  const { selectedClient, selectedClientCompanies, selectedCompany } = useClientStore();
  const dispatch = useClientDispatch();
  const [clients, setClients] = useState([]);
  const [timer, setTimer] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [numberOfEntities, setNumberOfEntities] = useState(0);
  const [nameQuery, setNameQuery] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [pageSize, setPageSize] = useState(Number(localStorage.getItem('pageSize')) || 10);
  const [checked, setChecked] = useState(false);
  
  useEffect(() => {
    localStorage.setItem('pageSize', pageSize);
  }, [pageSize]);

  useEffect(() => {
    const getClients = async () => {
      try {
        const res = await API.getClientsCompanies();
        setClients(res.data);
      } catch (error) {
        console.log('error -> ', error);
      }
    };
    getClients();
  }, []);

  useEffect(() => {
    if (!selectedClient.id && clients.length !== 0) {
      dispatch({ type: 'SET_CLIENT', value: clients[0] });
    }
  }, [clients, dispatch, selectedClient]);

  useEffect(() => {
    if (selectedCompany.id) {
      const search = async () => {
        let queryObject = {
          params: {
            which: status,
            tagName:
              selectedTags.length > 0 ? selectedTags.map((e) => e.label).join(' ') : undefined,
            name: nameQuery ? nameQuery : undefined,
            pageNumber: pageNumber,
            pageSize: pageSize,
            tagQuery: checked ? 'and' : undefined,

          },
        };
        let response = await API.getFollowers(selectedCompany.id, queryObject);
        setPageCount(response.data.pageCount);
        setNumberOfEntities(response.data.numberOfEntities);
        let followers = response.data.followers;
        setFollowers(
          followers.map((follower, i) => {
            follower.index = (pageNumber - 1) * pageSize + i + 1;
            follower.count = response.data.numberOfEntities;
            return {
              id: follower.id,
              avatar: follower.imageURL,
              name: follower.name,
              position: follower.occupation,
              date: follower.follower_companies[0].createdAt,
              email: follower.follower_companies[0].email,
              tags: getTagNames(follower.follower_companies[0].tags),
              original: follower,
            };
          })
        );
      };
      search();
    }
  }, [selectedCompany, nameQuery, pageNumber, pageSize, status, selectedTags, checked]);

  const getResultNumberString = () => {
    return (
      'Records ' +
      ((pageNumber - 1) * pageSize + 1) +
      '-' +
      ((pageNumber - 1) * pageSize + pageSize > numberOfEntities
        ? numberOfEntities
        : (pageNumber - 1) * pageSize + pageSize) +
      ' out of ' +
      numberOfEntities
    );
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onSearchChange = (e, searchType) => {
    // Debounce (we usually use libraries for this...)
    if (timer !== null) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        setPageNumber(1);
        searchType === 'name' ? setNameQuery(e.target.value) : setSelectedTags(e);
      }, 300)
    );
  };

  const getTags = (value) => {
    value &&
      API.getTags(value)
        .then((res) => {
          const tags = res.data.map((item) => {
            return { value: item.id, label: item.name };
          });
          setTags(tags);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const handleClientChange = (e) => {
    const client = clients.find((c) => c.id === e.target.value);
    dispatch({ type: 'SET_CLIENT', value: { ...client } });
  };

  const handleCompanyChange = (e) => {
    const company = selectedClientCompanies.find((c) => c.id === e.target.value);
    dispatch({ type: 'SET_COMPANY', value: { ...company } });
  };

  const getTagNames = (arrayTag) => {
    let res = [];
    for (let i = 0; i < arrayTag.length; i++) {
      res.push(arrayTag[i].name);
    }
    return res;
  };

  const refreshMultipleFollowers = (editedFollowers) => {
    if (!Array.isArray(editedFollowers)) {
      editedFollowers = [editedFollowers];
    }
    editedFollowers.forEach((editedFollower) => {
      followers.forEach((follower) => {
        if (follower.id === editedFollower.id) {
          follower.email = editedFollower.follower_companies[0].email;
          follower.tags = getTagNames(editedFollower.follower_companies[0].tags);
          follower.original = editedFollower;
        }
      });
    });
    setSelectedFollower(null);
    setFollowers([...followers]);
  };

  const download = async () => {
    const queryObject = {
      params: {
        which: status,
        tagName: selectedTags.length > 0 ? selectedTags.map((e) => e.label).join(' ') : undefined,
        name: nameQuery ? nameQuery : undefined,
        pageNumber: 1,
        pageSize: 100000000000,
      },
    };
    await API.downloadFollowers(selectedCompany.id, queryObject);
  };

  return (
    <Grid item md={12}>
      <Grid
        item
        md={12}
        className={selectedFollower ? `${classes.menu} ${classes.hideMenu}` : classes.menu}
      >
        <FormControl className={classes.formControl}>
          <InputLabel id="client-label">Client</InputLabel>
          <Select
            labelId="client-label"
            id="client"
            value={selectedClient.id && clients.length > 0 ? selectedClient.id : ''}
            onChange={handleClientChange}
            disabled={clients.length === 1}
          >
            {clients.length !== 0 &&
              clients.map((client, i) => {
                return (
                  <MenuItem key={i} value={client.id}>
                    {client.name || client.lastName
                      ? `${client.name ? client.name : ''} ${
                          client.lastName ? client.lastName : ''
                        }`
                      : `client_id: ${client.id}`}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="company-label">Company</InputLabel>
          <Select
            labelId="company-label"
            id="company"
            value={
              selectedCompany.id && selectedClientCompanies.length > 0 ? selectedCompany.id : ''
            }
            onChange={handleCompanyChange}
            disabled={selectedClientCompanies.length === 0}
          >
            {selectedClientCompanies &&
              selectedClientCompanies.length !== 0 &&
              selectedClientCompanies.map((company, i) => {
                return (
                  <MenuItem key={i} value={company.id}>
                    {company.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="following-status">Status</InputLabel>
          <Select
            labelId="following-status"
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="current">Followers</MenuItem>
            <MenuItem value="deleted">Unfollowed</MenuItem>
            <MenuItem value="all">All</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={download}
          className={classes.downloadBtn}
        >
          Download
        </Button>
      </Grid>
      <Grid
        item
        md={12}
        className={selectedFollower ? `${classes.menu} ${classes.hideMenu}` : classes.menu}
      >
        <FormControl className={classes.formControlSearch}>
          <TextField
            label="Search Name"
            type="search"
            onChange={(e) => onSearchChange(e, 'name')}
            InputProps={{
              className: classes.searchInput,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.icon} />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <ReactSelect
          placeholder={'Search for Tags...'}
          isMulti
          isClearable={true}
          onChange={(e) => onSearchChange(e, 'tag')}
          options={tags}
          onInputChange={(e) => getTags(e)}
          noOptionsMessage={() => 'No tags'}
        />
        <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleChange} size="medium" style={{marginLeft: '25px'}} color="primary"/>
            }
            label="Tags Logic (And)"
        />
        
        
      </Grid>
      <Grid
        item
        md={12}
        className={selectedFollower ? `${classes.records} ${classes.hideMenu}` : classes.records}
      >
        <span>{getResultNumberString()}</span>
        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value)); //it has to be a number
          }}
        >
          <MenuItem value="10">10</MenuItem>
          <MenuItem value="20">20</MenuItem>
          <MenuItem value="30">30</MenuItem>
        </Select>
      </Grid>
      <Paper className={classes.paper}>
        <Grid item xs={12} md={12} lg={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Breadcrumbs aria-label="breadcrumb" style={{ flex: '1' }}>
            <Button
              color="inherit"
              disabled={!selectedFollower}
              onClick={() => setSelectedFollower(null)}
            >
              {status === 'current' ? 'Followers' : status === 'deleted' ? 'Unfollowed' : 'All'}
            </Button>
            {selectedFollower && (
              <Button color="inherit" disabled>
                {selectedFollower.name}
              </Button>
            )}
          </Breadcrumbs>
          {selectedFollower && (
            <IconButton className={classes.backBtn} onClick={() => setSelectedFollower(null)}>
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
        <TableContainer className={selectedFollower ? classes.hide : ''}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                {tableColumns &&
                  tableColumns().map((cell, i) => {
                    return <TableCell key={i}>{cell.column_name}</TableCell>;
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {followers !== null &&
                followers.length !== 0 &&
                followers.map((follower, i) => {
                  return (
                    <TableRow
                      key={i}
                      className={classes.tableRow}
                      onClick={() => setSelectedFollower(follower.original)}
                    >
                      <TableCell className={classes.smallCell}>
                        {follower.avatar ? (
                          <Avatar
                            alt="Follower"
                            src={follower.avatar}
                            className={classes.largeAvatar}
                          />
                        ) : (
                          <Avatar className={classes.largeAvatar} />
                        )}
                      </TableCell>
                      <TableCell className={classes.nameCell}>{follower.name}</TableCell>
                      <TableCell className={classes.cellStyle}>{follower.position}</TableCell>
                      <TableCell className={classes.cellStyle}>
                        {formatDate(new Date(follower.date))}
                      </TableCell>
                      <TableCell className={classes.cellStyle}>{follower.email}</TableCell>
                      <TableCell>
                        {follower.tags.length !== 0 &&
                          follower.tags.map((tag, i) => {
                            return (
                              <span className={classes.tag} key={i}>
                                {tag}
                              </span>
                            );
                          })}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {followers !== null && followers.length === 0 && (
              <caption>{`This company has no ${
                status === 'deleted' ? 'unfollowed' : 'followers'
              } with the selected criteria.`}</caption>
            )}
          </Table>
          {selectedCompany.id && (
            <Pagination
              totalPages={pageCount}
              onChangePageNumber={(newPageNumber) => setPageNumber(newPageNumber)}
              selectedPage={pageNumber}
            />
          )}
        </TableContainer>
        {selectedFollower && (
          <EditFollower
            follower={selectedFollower}
            companyId={selectedCompany.id}
            onEditedFollower={refreshMultipleFollowers}
            goBack={() => setSelectedFollower(null)}
            nameQuery={nameQuery}
            selectedTags={selectedTags}
            which={status}
            index={selectedFollower.index}
            changeFollower={(data) => setSelectedFollower(data)}
          />
        )}
      </Paper>
    </Grid>
  );
}
