import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chart from './../components/Chart';
import { useHistory } from 'react-router-dom';
import { checkLogin } from './../helpers/checkLogin';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import API from '../API';
import {
  Breadcrumbs,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import tableColumns from './../utils/followersTable';
import { formatDate } from './../helpers/formatDate';
import Pagination from './../components/Pagination';
import EditFollower from './../components/EditFollower';
import Avatar from '@material-ui/core/Avatar';
import { useClientStore, useClientDispatch } from '../contexts/ClientContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    marginTop: '20px',
  },
  menu: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  hideMenu: {
    visibility: 'hidden',
    height: 0,
  },
  icon: {
    color: '#3f51b5',
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cellStyle: {
    maxWidth: '250px',
  },
  smallCell: {
    width: '80px',
  },
  nameCell: {
    width: '180px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  hide: {
    opacity: 0,
    position: 'absolute',
    height: 0,
    overflow: 'hidden',
    zIndex: -1,
  },
  formControl: {
    marginRight: theme.spacing(3),
    minWidth: 120,
    marginBottom: 10,
  },
  formControlSearch: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  searchInput: {
    paddingRight: '10px',
  },
  expandCell: {
    width: '100%',
    textAlign: 'center',
  },
  tag: {
    display: 'block',
  },
}));

export default function Home() {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);

  const { selectedClient, selectedClientCompanies, selectedCompany } = useClientStore();
  const dispatch = useClientDispatch();

  const [clients, setClients] = useState([]);

  const [followersAdded, setFollowersAdded] = useState([]);
  const [pageNumberAdded, setPageNumberAdded] = useState(1);
  const [pageCountAdded, setPageCountAdded] = useState(0);

  const [followersRemoved, setFollowersRemoved] = useState([]);
  const [pageNumberRemoved, setPageNumberRemoved] = useState(1);
  const [pageCountRemoved, setPageCountRemoved] = useState(0);

  const [selectedFollower, setSelectedFollower] = useState(null);
  const [searchDate, setSearchDate] = useState('');
  const [totalAddedCount, setTotalAddedCount] = useState(0);
  const [totalRemovedCount, setTotalRemovedCount] = useState(0);

  useEffect(() => {
    if (!checkLogin()) {
      history.push('/login');
    }
  }, [history]);

  useEffect(() => {
    const getClients = async () => {
      try {
        const res = await API.getClientsCompanies();
        setClients(res.data);
      } catch (error) {
        console.log('error -> ', error);
      }
    };
    getClients();
  }, []);

  useEffect(() => {
    if (!selectedClient.id && clients.length !== 0) {
      dispatch({ type: 'SET_CLIENT', value: clients[0] });
    }
  }, [clients, dispatch, selectedClient]);

  useEffect(() => {
    const getStatistics = async (companyID) => {
      if (companyID) {
        let reaponce = await API.getStatistics('Day', companyID);
        setData(reaponce.data);
      }
    };
    getStatistics(selectedCompany.id);
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany.id && searchDate) {
      const search = async () => {
        const queryObjectAdded = {
          params: {
            action: 'added',
            pageNumber: pageNumberAdded,
            searchDate: searchDate,
          },
        };
        const responseAdded = await API.getHistoryFollowers(selectedCompany.id, queryObjectAdded);
        setPageCountAdded(responseAdded.data.pageCount);
        setTotalAddedCount(responseAdded.data.count)
        const historyAdded = responseAdded.data.history;
        setFollowersAdded(
          historyAdded.map((record,i) => {
            record.index = (pageNumberAdded - 1)  * pageCountAdded + i + 1;
            const item = {
              id: record.id,
              index: record.index,
              avatar: record.imageURL,
              name: record.name,
              position: record.occupation,
              original: record,
            };
            if (record.follower_companies && record.follower_companies.length > 0) {
              item.date = record.follower_companies[0].createdAt;
              item.email = record.follower_companies[0].email;
              item.tags = getTagNames(record.follower_companies[0].tags);
            }
            return item;
          })
        );
        const queryObjectRemoved = {
          params: {
            action: 'removed',
            pageNumber: pageNumberRemoved,
            searchDate: searchDate,
          },
        };
        const responseRemoved = await API.getHistoryFollowers(
          selectedCompany.id,
          queryObjectRemoved
        );
        setPageCountRemoved(responseRemoved.data.pageCount);
        setTotalRemovedCount(responseRemoved.data.count)
        const followersRemoved = responseRemoved.data.history;
        setFollowersRemoved(
          followersRemoved.map((record, i) => {
            record.index = (pageNumberRemoved - 1)  * pageCountRemoved + i + 1;
            const item = {
              id: record.id,
              index: record.index,
              avatar: record.imageURL,
              name: record.name,
              position: record.occupation,
              original: record,
            };
            if (record.follower_companies && record.follower_companies.length > 0) {
              item.date = record.follower_companies[0].createdAt;
              item.email = record.follower_companies[0].email;
              item.tags = getTagNames(record.follower_companies[0].tags);
            }
            return item;
          })
        );
      };
      search();
    }
  }, [selectedCompany, pageNumberAdded, pageNumberRemoved, searchDate]);

  const getTagNames = (arrayTag) => {
    let res = [];
    for (let i = 0; i < arrayTag.length; i++) {
      res.push(arrayTag[i].name);
    }
    return res;
  };

  const handleClientChange = (e) => {
    const client = clients.find((c) => c.id === e.target.value);
    dispatch({ type: 'SET_CLIENT', value: { ...client } });
  };

  const handleCompanyChange = (e) => {
    const company = selectedClientCompanies.find((c) => c.id === e.target.value);
    dispatch({ type: 'SET_COMPANY', value: { ...company } });
  };

  const refreshFollowers = (editedFollower) => {
    setSelectedFollower(null);
    let refreshedFollowers = [];
    followersAdded.forEach((follower) => {
      if (follower.id === editedFollower.id) {
        let newFollowerValues = {
          id: follower.id,
          index: follower.index,
          avatar: follower.avatar,
          name: follower.name,
          position: follower.position,
          date: follower.date,
          email: editedFollower.email,
          tags: getTagNames(editedFollower.tags),
          original: editedFollower,
        };
        refreshedFollowers.push(newFollowerValues);
      } else {
        refreshedFollowers.push(follower);
      }
    });
    setFollowersAdded(null);
    setFollowersAdded(refreshedFollowers);
  };

    console.log('stanje: ', totalAddedCount, totalRemovedCount)
  return (
    <Grid item xs={12} md={12} lg={12}>
      <div className={selectedFollower ? `${classes.menu} ${classes.hideMenu}` : classes.menu}>
        <FormControl className={classes.formControl}>
          <InputLabel id="client-label">Client</InputLabel>
          <Select
            labelId="client-label"
            id="client"
            value={selectedClient.id && clients.length > 0 ? selectedClient.id : ''}
            onChange={handleClientChange}
            disabled={clients.length === 0}
          >
            {clients.length !== 0 &&
              clients.map((client) => {
                return (
                  <MenuItem key={client.id} value={client.id}>
                    {client.name || client.lastName
                      ? `${client.name ? client.name : ''} ${
                          client.lastName ? client.lastName : ''
                        }`
                      : `client_id: ${client.id}`}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="company-label">Company</InputLabel>
          <Select
            labelId="company-label"
            id="company"
            value={
              selectedCompany.id && selectedClientCompanies.length > 0 ? selectedCompany.id : ''
            }
            onChange={handleCompanyChange}
            disabled={selectedClientCompanies.length === 0}
          >
            {selectedClientCompanies.length !== 0 &&
              selectedClientCompanies.map((company) => {
                return (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>
      <Paper className={selectedFollower ? classes.hide : classes.paper}>
        <Chart
          dotsParam={data}
          getSearchDate={(dot) => {
            setPageNumberAdded(1);
            setSearchDate(dot.time);
          }}
        />
      </Paper>
      {(followersAdded?.length !== 0 || followersRemoved?.length !== 0) && (
        <Paper className={classes.paper}>
          <Breadcrumbs aria-label="breadcrumb">
            <Button
              color="inherit"
              disabled={!selectedFollower}
              onClick={() => setSelectedFollower(null)}
            >
              Followers
            </Button>
            {selectedFollower && (
              <Button color="inherit" disabled>
                {selectedFollower.name}
              </Button>
            )}
          </Breadcrumbs>
          {followersRemoved?.length !== 0 && (
            <TableContainer
              className={selectedFollower ? classes.hide : ''}
              style={{ marginBottom: '40px' }}
            >
              <Typography style={{ color: 'red', paddingLeft: '6px' }}>Leavers</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableColumns &&
                      tableColumns().map((cell, i) => {
                        return <TableCell key={i}>{cell.column_name}</TableCell>;
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {followersRemoved &&
                    followersRemoved.length &&
                    followersRemoved.map((follower, i) => {
                      return (
                        <TableRow
                          key={i}
                          className={classes.tableRow}
                          onClick={() => {
                            follower.original.count = totalRemovedCount;
                            setSelectedFollower(follower.original);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <TableCell>
                            {follower.avatar ? (
                              <Avatar alt="Follower" src={follower.avatar} />
                            ) : (
                              <Avatar className={classes.largeAvatar} />
                            )}
                          </TableCell>
                          <TableCell>{follower.name}</TableCell>
                          <TableCell>{follower.position}</TableCell>
                          <TableCell>{formatDate(new Date(follower.date))}</TableCell>
                          <TableCell>{follower.email}</TableCell>
                          <TableCell>
                            {follower.tags &&
                              follower.tags.length !== 0 &&
                              follower.tags.map((tag, i) => {
                                return <span key={i}>{tag}</span>;
                              })}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {selectedCompany.id && (
                <Pagination
                  totalPages={pageCountRemoved}
                  onChangePageNumber={(newPageNumber) => setPageNumberRemoved(newPageNumber)}
                  selectedPage={pageNumberRemoved}
                />
              )}
            </TableContainer>
          )}
          {followersAdded?.length !== 0 && (
            <TableContainer className={selectedFollower ? classes.hide : ''}>
              <Typography style={{ color: 'blue', paddingLeft: '6px' }}>Joiners</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableColumns &&
                      tableColumns().map((cell, i) => {
                        return <TableCell key={i}>{cell.column_name}</TableCell>;
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {followersAdded &&
                    followersAdded.length &&
                    followersAdded.map((follower, i) => {
                      return (
                        <TableRow
                          key={i}
                          className={classes.tableRow}
                          onClick={() => {
                            follower.original.count = totalAddedCount;
                            setSelectedFollower(follower.original);
                            window.scrollTo(0, 0);
                          }}
                        >
                          <TableCell>
                            {follower.avatar ? (
                              <Avatar alt="Follower" src={follower.avatar} />
                            ) : (
                              <Avatar className={classes.largeAvatar} />
                            )}
                          </TableCell>
                          <TableCell>{follower.name}</TableCell>
                          <TableCell>{follower.position}</TableCell>
                          <TableCell>{formatDate(new Date(follower.date))}</TableCell>
                          <TableCell>{follower.email}</TableCell>
                          <TableCell>
                            {follower.tags &&
                              follower.tags.map((tag, i) => {
                                return <span key={i}>{tag}</span>;
                              })}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {selectedCompany.id && (
                <Pagination
                  totalPages={pageCountAdded}
                  onChangePageNumber={(newPageNumber) => setPageNumberAdded(newPageNumber)}
                  selectedPage={pageNumberAdded}
                />
              )}
            </TableContainer>
          )}
          
          {selectedFollower && (
            <EditFollower
              follower={selectedFollower}
              companyId={selectedCompany.id}
              onEditedFollower={refreshFollowers}
              goBack={() => setSelectedFollower(null)}
              index={selectedFollower.index}
              isHistory={true}
              date={searchDate}
              changeFollower={(data) => setSelectedFollower(data)}
              count={selectedFollower.count}
            />
          )}
        </Paper>
      )}
    </Grid>
  );
}
