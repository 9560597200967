import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, Tooltip, ResponsiveContainer } from 'recharts';
import Title from './Title';

export default function Chart({ dotsParam = [], getSearchDate = null }) {
  const theme = useTheme();

  const onClickDot = (e) => {
    if (
      getSearchDate &&
      e &&
      e.activePayload &&
      e.activePayload.length !== 0 &&
      e.activePayload[0].payload
    ) {
      getSearchDate(e.activePayload[0].payload);
    }
  };

  return (
    <React.Fragment>
      <Title>Change in followers per day</Title>
      <div style={{ height: '300px' }}>
        <ResponsiveContainer height="100%" width="100%">
          <LineChart
            data={dotsParam.map((dot) => {
              return { Joiners: dot.added, Leavers: dot.removed, time: dot.time };
            })}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
            onClick={onClickDot}
            height={250}
            width={730}
          >
            <XAxis
              dataKey="time"
              stroke={theme.palette.text.secondary}
              label={dotsParam.length === 0 && 'No Available Data'}
            />
            <YAxis stroke={theme.palette.text.secondary} allowDecimals={false}>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
              >
                Change in followers
              </Label>
            </YAxis>
            <Line type="monotone" dataKey="Joiners" stroke="blue" />
            <Line type="monotone" dataKey="Leavers" stroke="red" />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </React.Fragment>
  );
}
