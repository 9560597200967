import createStore from '../store';
import { reducer } from '../reducers/clientReducer';

const initalState = {
  selectedClient: { id: '', name: '', lastName: '', username: '' },
  selectedClientCompanies: [],
  selectedCompany: { id: '', name: '' },
};
const [ClientProvider, useClientStore, useClientDispatch] = createStore(reducer, initalState);

export { ClientProvider, useClientStore, useClientDispatch };
