import React, { useState, useEffect } from 'react';
import Title from './../components/Title';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import API from '../API';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import tableColumns from './../utils/clientsTable';
import Avatar from '@material-ui/core/Avatar';
import Modal from '../components/Modal';
import ClientForm from '../components/ClientForm';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  icon: {
    color: '#3f51b5',
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  actionBtn: {
    padding: '5px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3px',
    },
  },
  modalBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    overflow: 'hidden',
  },
  modalHeader: {
    backgroundColor: '#3f51b5',
    height: '30px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
  },
  modalHeaderTitle: {
    flex: '1',
  },
  table: {
    width: '100%',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '15px 0',
  },
}));

export default function Clients() {
  const classes = useStyles();
  const [clients, setClients] = useState([]);
  const [clientModal, setClientModal] = useState(false);
  const [client, setClient] = useState(null);
  const [error, setError] = useState('');
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const getClients = async () => {
    try {
      const res = await API.getClients();
      setClients(res.data);
    } catch (error) {
      console.log('error -> ', error);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const editClient = async (edited) => {
    try {
      const res = await API.editClient({
        ...edited,
        clientId: client.id,
      });
      const index = clients.findIndex((c) => c.id === res.data.id);
      const updateClients = clients;
      updateClients.splice(index, 1, res.data);
      setClients(updateClients);
      closeModal();
    } catch (error) {
      setError(error);
      console.log('error -> ', error);
    }
  };

  const createClient = async (newClient) => {
    try {
      const res = await API.createClient({
        ...newClient,
      });
      setClients([...clients, res.data]);
      closeModal();
    } catch (error) {
      console.log('error -> ', error);
      setError(error);
    }
  };

  const deleteClient = async () => {
    try {
      const res = await API.deleteClient(client.id);
      const index = clients.findIndex((c) => c.id === res.id);
      const updateClients = clients;
      updateClients.splice(index, 1);
      setClients(updateClients);
      cancelDelete();
    } catch (error) {
      console.log('error -> ', error);
    }
  };

  const closeModal = () => {
    setClientModal(false);
    setClient(null);
    setError('');
  };

  const cancelDelete = () => {
    setConfirmationDialog(false);
    setClient(null);
  };

  return (
    <Grid item md={12}>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: '20px' }}
        onClick={() => {
          setClient(null);
          setClientModal(true);
        }}
      >
        Add client
      </Button>
      <Paper className={classes.paper}>
        <Title>Clients</Title>
        <Table size="medium" className={classes.table}>
          <TableHead>
            <TableRow>
              {tableColumns &&
                tableColumns().map((cell, i) => {
                  return <TableCell key={i}>{cell.column_name}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    {client.imageURL ? (
                      <Avatar alt="Client" src={client.imageURL} className={classes.largeAvatar} />
                    ) : (
                      <Avatar className={classes.largeAvatar} />
                    )}
                  </TableCell>
                  <TableCell>{`${client.firstName ? client.firstName : ''} ${
                    client.lastName ? client.lastName : ''
                  }`}</TableCell>
                  <TableCell>{client.username}</TableCell>
                  <TableCell>{client.occupation}</TableCell>
                  <TableCell>{client.token === null ? 'Not Valid' : 'Valid'}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.actionBtn}
                      style={{ marginRight: '3px' }}
                      onClick={() => {
                        setClient(client);
                        setConfirmationDialog(true);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.actionBtn}
                      onClick={() => {
                        setClient(client);
                        setClientModal(true);
                      }}
                    >
                      <EditIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <Modal
        isOpen={clientModal}
        onClose={closeModal}
        title={
          client
            ? `CLIENT ${client.firstName ? client.firstName : ''} ${
                client.lastName ? client.lastName : ''
              }`
            : 'NEW CLIENT'
        }
      >
        <ClientForm
          onCancel={closeModal}
          client={client}
          onSubmit={(event) => (client ? editClient(event) : createClient(event))}
          serverError={error}
        />
      </Modal>
      <Modal isOpen={confirmationDialog} title="Delete Client" onClose={cancelDelete}>
        {`Are you sure you want do delete client ${client?.name ? client.name : ''}?`}
        <div className={classes.btns}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={cancelDelete}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={deleteClient}>
            Delete
          </Button>
        </div>
      </Modal>
    </Grid>
  );
}
