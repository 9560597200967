import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRighttIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  btnStyle: {
    padding: '5px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    minWidth: '30px',
    margin: '0 3px',
  },
  doubleArrowLeft: {
    padding: '12px 8px',
    marginRight: '-10px',
    '& svg:nth-child(2)': {
      marginLeft: '-15px',
    },
  },
  doubleArrowRight: {
    padding: '12px 8px',
    marginLeft: '-10px',
    '& svg:nth-child(2)': {
      marginLeft: '-15px',
    },
  },
}));
export default function Pagination({ onChangePageNumber = null, totalPages, selectedPage }) {
  const classes = useStyles();
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const generatePages = (currentPage) => {
      let min = 1; // first page
      let range = 5; // page numbers displayed

      if (range > totalPages) range = totalPages;

      let start = currentPage - Math.floor(range / 2);
      start = Math.max(start, min);
      start = Math.min(start, min + totalPages - range);

      setPages(Array.from({ length: range }, (el, i) => start + i));
    };
    generatePages(selectedPage);
  }, [totalPages, selectedPage]);

  const previousPageHandler = () => {
    onChangePageNumber(selectedPage - 1);
  };

  const nextPageHandler = () => {
    onChangePageNumber(selectedPage + 1);
  };

  const selectPageHandler = (e) => {
    onChangePageNumber(parseInt(e.currentTarget.id, 10));
  };

  const goToFirstPage = () => {
    onChangePageNumber(1);
  };

  const goToLastPage = () => {
    onChangePageNumber(totalPages);
  };

  return totalPages > 1 ? (
    <div
      style={{
        height: '70px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <IconButton
        className={classes.doubleArrowLeft}
        onClick={goToFirstPage}
        disabled={selectedPage <= 1}
      >
        <ChevronLeftIcon />
        <ChevronLeftIcon />
      </IconButton>
      <IconButton onClick={previousPageHandler} disabled={selectedPage <= 1}>
        <ChevronLeftIcon />
      </IconButton>
      {pages.map((page, i) => {
        return (
          <Button
            key={i}
            id={page}
            className={classes.btnStyle}
            variant="contained"
            onClick={selectPageHandler}
            disabled={selectedPage === page}
          >
            {page}
          </Button>
        );
      })}
      <IconButton onClick={nextPageHandler} disabled={selectedPage === totalPages}>
        <ChevronRighttIcon />
      </IconButton>
      <IconButton
        className={classes.doubleArrowRight}
        onClick={goToLastPage}
        disabled={selectedPage === totalPages}
      >
        <ChevronRighttIcon />
        <ChevronRighttIcon />
      </IconButton>
    </div>
  ) : null;
}
