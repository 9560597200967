import axios from 'axios';

// intercept each request
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (401 === error.response.status) {
      // try and refresh token
      try {
        await axios.get('/api/auth/token/refresh');
        return axios.request(error.config);
      } catch (error) {
        window.location.replace('/login');
      }
    } else {
      return Promise.reject(error.response.data.error);
    }
  }
);

const exportObj = {
  login(loginData) {
    return axios.post('/api/auth/login', loginData);
  },
  logout() {
    return axios.post('/api/auth/logout');
  },
  getClients() {
    return axios.get('/api/clients');
  },
  getFollowers(companyID, queryObj) {
    return axios.get(`/api/followers/${companyID}`, queryObj);
  },
  getHistoryFollowers(companyID, queryObj) {
    return axios.get(`/api/followers/history/${companyID}`, queryObj);
  },
  getClientsCompanies() {
    return axios.get('/api/clients/companies');
  },
  patchSetRead(jsonBody) {
    return axios.patch('/api/companies/setRead', jsonBody);
  },
  postFetchNow(jsonBody) {
    return axios.post('/api/companies/fetchNow', jsonBody);
  },
  editClient(jsonBody) {
    return axios.patch('/api/clients', jsonBody);
  },
  createClient(jsonBody) {
    return axios.post('/api/clients', jsonBody);
  },
  deleteClient(id) {
    return axios.delete(`/api/clients/${id}`);
  },
  follower_companyPatch(jsonBody) {
    return axios.patch('/api/follower_companies', jsonBody);
  },
  follower_companyGet(queryObj) {
    return axios.get('/api/follower_companies', queryObj);
  },
  getTags(string) {
    return axios.get('/api/tags/' + string);
  },
  postSingleTag(jsonBody) {
    return axios.post('/api/tags/single', jsonBody);
  },
  deleteTag(queryObject) {
    return axios.delete('/api/tags', queryObject);
  },
  postMultipleTags(jsonBody) {
    return axios.post('/api/tags/multiple', jsonBody);
  },
  getStatistics(per, companyId) {
    return axios.get(`/api/followers/statistics/delta/${per}/${companyId}`);
  },
  downloadFollowers(companyId, queryObject) {
    return axios(
      {
        url: `/api/followers/${companyId}/excell`,
        method: 'GET',
        responseType: 'blob', // important
        params: queryObject.params,
      },
      queryObject
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'followers.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  },
};

export default exportObj;
