import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Switch, Route, Redirect } from 'react-router-dom';
import SideDrawer from './SideDrawer';
import Home from './../pages/home';
import Followers from './../pages/followers';
import Clients from './../pages/clients';
import Companies from './../pages/companies';
import Header from './Header';
import { ClientProvider } from '../contexts/ClientContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    width: 'calc(100% - 56px)',
    minHeight: '100vh',
    '&.moveRight': {
      paddingLeft: '240px',
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: '100%',
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [sideDrawerOpen, setSideDrawerOpen] = useState(true);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header open={sideDrawerOpen} openDrawer={() => setSideDrawerOpen(true)} />
      <SideDrawer open={sideDrawerOpen} closeDrawer={() => setSideDrawerOpen(false)} />
      <main className={`${sideDrawerOpen ? 'moveRight' : ''} ${classes.content}`}>
        <div className={classes.appBarSpacer} />
        <ClientProvider>
          <Container maxWidth="lg" className={classes.container}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/clients" component={Clients} />
              <Route path="/companies" component={Companies} />
              <Route path="/followers">
                <Followers />
              </Route>
              <Route path="*">
                <Redirect to={'/'}></Redirect>
              </Route>
            </Switch>
          </Container>
        </ClientProvider>
      </main>
    </div>
  );
}
