import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import API from '../API';
import { checkLogin } from './../helpers/checkLogin';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LogIn() {
  const classes = useStyles();
  const history = useHistory();
  const [userData, setUserData] = useState({
    username: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [warningMsg, setWarningMsg] = useState('');
  const [passwordError, setPassError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);

  useEffect(() => {
    if (checkLogin()) {
      history.push('/');
    }
  }, [history]);

  const handleChange = (e) => {
    setPassError(false);
    setUsernameError(false);
    setWarningMsg('');
    setUserData({
      ...userData,
      [e.target.id]: e.target.value,
    });
  };

  const handleLogin = async () => {
    if (!userData.password && !userData.username) {
      setPassError(true);
      setUsernameError(true);
      return;
    } else if (!userData.username) {
      setUsernameError(true);
      return;
    } else if (!userData.password) {
      setPassError(true);
      return;
    }
    setIsLoading(true);
    try {
      const res = await API.login(userData);
      localStorage.setItem('user', res.data.email);
      setIsLoading(false);
      history.push('/');
    } catch (error) {
      setIsLoading(false);
      if (error === 'user not found') {
        setWarningMsg('User is not found.');
        setUsernameError(true);
      } else if (error === 'wrong password') {
        setWarningMsg('Wrong password.');
        setPassError(true);
      } else {
        setWarningMsg('Something went wrong.');
        console.log('error -> ', error);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 13) {
        handleLogin();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log In
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={handleChange}
            disabled={isLoading}
            error={usernameError}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
            disabled={isLoading}
            error={passwordError}
          />
          <p style={{ color: '#f44336', margin: 0 }}>{warningMsg}</p>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogin}
            disabled={isLoading}
          >
            Log In
          </Button>
        </form>
      </div>
    </Container>
  );
}
