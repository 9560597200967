import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './pages/login';

function App() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route path="/" component={Dashboard} />
    </Switch>
  );
}

export default App;
