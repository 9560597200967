import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import ApartmentIcon from '@material-ui/icons/Apartment';
import ContactsIcon from '@material-ui/icons/Contacts';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selectedSytle: {
    backgroundColor: '#3f51b5',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#3f51b5',
      opacity: 0.9,
    },
  },
  whiteIcon: {
    color: 'white',
  },
}));

export default function DrawerList() {
  const pathname = useLocation().pathname;
  const classes = useStyles();

  return (
    <List>
      <Link to="/">
        <ListItem button className={pathname === '/' ? classes.selectedSytle : ''}>
          <ListItemIcon>
            <HomeIcon className={pathname === '/' ? classes.whiteIcon : ''} />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      </Link>
      <Link to="/clients">
        <ListItem button className={pathname === '/clients' ? classes.selectedSytle : ''}>
          <ListItemIcon>
            <ContactsIcon className={pathname === '/clients' ? classes.whiteIcon : ''} />
          </ListItemIcon>
          <ListItemText primary="Clients" />
        </ListItem>
      </Link>
      <Link to="/companies">
        <ListItem button className={pathname === '/companies' ? classes.selectedSytle : ''}>
          <ListItemIcon>
            <ApartmentIcon className={pathname === '/companies' ? classes.whiteIcon : ''} />
          </ListItemIcon>
          <ListItemText primary="Companies" />
        </ListItem>
      </Link>
      <Link to="/followers">
        <ListItem button className={pathname === '/followers' ? classes.selectedSytle : ''}>
          <ListItemIcon>
            <PeopleIcon className={pathname === '/followers' ? classes.whiteIcon : ''} />
          </ListItemIcon>
          <ListItemText primary="Followers" />
        </ListItem>
      </Link>
    </List>
  );
}
