const formatTime = (value) => {
  let time = new Date(value).toLocaleTimeString('EN', {
    timeStyle: 'short',
    hour12: false,
  });
  return time + 'h';
};

const formatDate = (value) => {
  let date = new Date(value).toLocaleDateString('EN', {
    weekday: 'short',
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
  return date;
};

export { formatTime, formatDate };
